import { Router } from "@reach/router"
import React from "react"
import Layout from "components/layout"
import SEO from "components/seo"
import Posts from "components/discoverPage/Posts"

const DiscoverPage = () => {
  return (
    <Layout>
      <SEO title="Discover" />
      <Router>
        <Posts title="Discover" path="/discover" />
      </Router>
    </Layout>
  )
}
export default DiscoverPage
